"use client";
import { useRouter } from "next/navigation";
import swal from "sweetalert";
import { errorHandler } from "@/config/errorReporting";
import { useSignIn } from "@/modules/user/infraestructure/services/auth";
import Image from "next/image";
import { CardContainer } from "src/storybook/components/cards/Utils/Card";
import { Button, Loader, Title } from "@umahealth/occipital";
import { Input } from "@umahealth/occipital/client";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect } from "react";
import { auth } from "@/config/firebaseApp";
import { removeAuthCookies } from "@/modules/users/service/auth";
import { useUpdateTokens } from "../services/useUpdateTokens";

export default function LoginComponent({
	logoSrc,
	searchParams,
}: {
	logoSrc: string;
	searchParams?: {
		customToken?: string;
		redirect?: string;
		verifyCache?: "true";
	};
}): JSX.Element {
	const router = useRouter();
	const signIn = useSignIn();

	const updateTokens = useUpdateTokens({
		options: {
			onSuccess: () => {
				router.push(searchParams?.redirect ?? "/");
			},
			onError: async () => {
				localStorage.clear();
				removeAuthCookies();
				signOut(auth);
				window.location.replace("/usuarios/acceso");
			},
		},
	});

	useEffect(() => {
		if (searchParams?.verifyCache === "true") {
			const authStateChange = onAuthStateChanged(auth, (user) => {
				if (user) {
					updateTokens.mutate(user);
				}
			});
			return () => authStateChange();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth, searchParams?.verifyCache]);

	async function submitSignIn(e: React.SyntheticEvent) {
		e.preventDefault();
		const target = e.target as typeof e.target & {
			email: { value: string };
			password: { value: string };
		};
		const email = target.email.value;
		const password = target.password.value;

		if (email.length < 5 || password.length < 5) {
			return;
		}

		signIn.mutate(
			{
				email: email,
				password: password,
			},
			{
				onSuccess: (user) => {
					if (user?.uid) {
						router.push("/operaciones");
					}
				},
				onError: (error) => {
					let popTitle = "Ocurrió un problema";
					let popMessage;

					if (error.message.includes("auth/user-not-found")) {
						popMessage =
							"Lo sentimos, no pudimos encontrar tu cuenta";
					} else if (error.message.includes("auth/wrong-password")) {
						popTitle = "Credenciales inválidas";
						popMessage =
							"Por favor, ingresa un correo electrónico y contraseña válida";
					} else if (error.message === "No tienes permisos") {
						popTitle = "Permisos insuficientes";
						popMessage =
							"No tienes permisos para acceder, comunícate con un supervisor";
					} else {
						popMessage = `${error.message}`;
						errorHandler?.report(
							`Error iniciando sesión: ${error}`
						);
						console.log("error", error);
					}

					swal({
						title: popTitle,
						text: popMessage,
						timer: 4000,
						buttons: {
							confirm: false,
						},
						icon: "warning",
					});
				},
			}
		);
	}

	if (
		searchParams?.customToken ||
		searchParams?.verifyCache ||
		updateTokens.isSuccess ||
		updateTokens.isPending
	) {
		return (
			<div className="h-full grid place-content-center justify-center h gap-4">
				<Loader
					className="m-auto"
					size="size-7"
					color="stroke-primary"
				/>
			</div>
		);
	}


	return (
		<CardContainer className="border-none shadow-shortDefault max-w-[592px] p-8 h-3/4">
			<div className="w-full flex flex-col justify-center items-center">
				<div className="w-full flex flex-col justify-center items-center mb-16 pt-24">
					<Image src={logoSrc} alt="" height={140} width={140} />
				</div>
				<Title
					color="text-primary-800"
					hierarchy="h1"
					weight="font-bold"
					size="text-l"
					className="w-full mb-10"
				>
					Inicio de sesión
				</Title>
				<form
					className="flex flex-col w-full"
					onSubmit={(e) => submitSignIn(e)}
				>
					<Input
						name="email"
						type="email"
						placeholder="test@gmail.com"
						label="Ingresá tu correo electrónico"
						classNameContainer="mt-0"
						autoComplete="email"
						inputMode='email'
						autoFocus
						required
					/>
					<Input
						name="password"
						type="password"
						autoComplete="current-password"
						label="Ingresá tu contraseña"
						required
					/>
					<Button
						type="submit"
						className="button mt-6 mb-24 w-full max-w-72 self-center"
						loading={signIn.isPending || signIn.isSuccess}
					>
						Iniciar sesión
					</Button>
				</form>
			</div>
		</CardContainer>
	);
}

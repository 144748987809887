import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { auth } from "@/config/firebaseApp"; 
import { userIsAllowed } from "@/utils/functions"; 
import { loginUser } from "@/modules/users/infraestructure/ssrUserRequests"; 
import { removeAuthCookies, setAuthServerToken } from "@/modules/users/service/auth";
import { useMutation } from "@tanstack/react-query";


export function useSignIn(){
  return useMutation({
    mutationFn: signIn
  })
}

export const signIn = async ({email, password} : {email:string, password:string}) => {
    const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);

    const idTokenResult = await firebaseUser?.getIdTokenResult();
    const user = await getLoggedUserData(idTokenResult.token);

    // Check if user is admin
    if (!user.roles || !userIsAllowed(user.roles)) {
      await signOut(auth)
      await removeAuthCookies()
      throw new Error('Tu cuenta no tiene permisos suficientes para entrar al portal')
    }

    await setAuthServerToken(idTokenResult.token, firebaseUser.uid,  idTokenResult.expirationTime);

    return { ...firebaseUser, data: user };
}

export const getLoggedUserData = async (token: string) => {
  const user = await loginUser(token);
  return user;
}